@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

body {
  margin: 0;
}
html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

.app{
  display: flex;
  position: relative;
  height: fit-content;
}

a{
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}
.container{
    padding-left: -10%;
    padding-right: 10%;
}
.btn{
  background:  #fff;
  color: #212121;
  padding: 14px, 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}